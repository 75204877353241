import React, { Fragment, useState } from 'react';
import { TextField, Link, FormControl, InputLabel, Box, Grid, OutlinedInput, InputAdornment, Button, Typography, Fab, Card, CardMedia, CardContent, CardActions, Rating, Accordion, AccordionDetails, AccordionSummary, Checkbox, Autocomplete, FormControlLabel, Switch } from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import useComponentVisible from 'features/shared/useComponentVisible/useComponentVisible';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PauseIcon from '@mui/icons-material/Pause';
import { common } from "@mui/material/colors";
import { darken, mediaType } from 'utils/utils';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function WidgetForm(props) {
  const { t } = useTranslation();
  const { formik, error } = props;
  const [open, setOpen] = useState(true);
  const [callStatus, setCallStatus] = useState(''); // form, standBy, ringing, inProgress, completed
  const [rating, setRating] = React.useState(3);
  const [muted, setMuted] = useState(false);
  const [onHold, setOnHold] = useState(false);
  const [openDialPad, setOpenDialPad] = useState(false);
  const handlePanelChange = (panel) => (event, isExpanded) => { setCallStatus(isExpanded ? panel : false); setOpenDialPad(false) };
  const { ref: refMainColorPicker, isComponentVisible: displayMainColorPicker, setIsComponentVisible: setDisplayMainColorPicker } = useComponentVisible(false);
  const { ref: refSuccessColorPicker, isComponentVisible: displaySuccessColorPicker, setIsComponentVisible: setDisplaySuccessColorPicker } = useComponentVisible(false);
  const { ref: refErrorColorPicker, isComponentVisible: displayErrorColorPicker, setIsComponentVisible: setDisplayErrorColorPicker } = useComponentVisible(false);

  const cardMedia = (url) =>
    <>
      {
        mediaType(url) === 'image' &&
        <CardMedia
          component="img"
          alt=""
          height="170"
          src={url}
        />
      }
      {
        mediaType(url) === 'video' &&
        <video width="300" height="170" autoPlay loop muted style={{ marginTop: '-1px' }}>
          <source src={url} />
        </video>
      }
    </>;

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label={t("__widget_form_name")}
                fullWidth
                autoFocus
                {...formik.getFieldProps('name')}
                error={Boolean(formik.submitCount) && (Boolean(formik.errors.name) || Boolean(error?.error_details?.name))}
                helperText={Boolean(formik.submitCount) && (formik.errors.name || error?.error_details?.name)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="description"
                label={t("__widget_form_description")}
                fullWidth
                minRows={2}
                multiline
                {...formik.getFieldProps('description')}
                error={(Boolean(formik.submitCount) && Boolean(formik.errors.description)) || Boolean(error?.error_details?.description)}
                helperText={Boolean(formik.submitCount) && (formik.errors.description || error?.error_details?.description)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ColorLensOutlinedIcon sx={{ mr: 1 }} />
                <Typography>{t('__widget_colors')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel htmlFor="colorMain">Primary</InputLabel>
                      <OutlinedInput
                        id="colorMain"
                        label="Primary"
                        value={formik.values?.config?.colors?.main}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box sx={{
                              width: '25px',
                              height: '25px',
                              borderRadius: '5px',
                              border: '2px white solid',
                              backgroundColor: formik.values?.config?.colors?.main
                            }} />
                          </InputAdornment>
                        }
                        onClick={() => setDisplayMainColorPicker(!displayMainColorPicker)}
                      />
                    </FormControl>

                    <Box sx={{ position: 'absolute', zIndex: '1000' }} ref={refMainColorPicker}>
                      {displayMainColorPicker &&
                        <ChromePicker
                          color={formik.values?.config?.colors?.main}
                          onChange={(value) => formik.setFieldValue('config.colors.main', value?.hex)}
                          disableAlpha
                        />
                      }
                    </Box>

                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel htmlFor="colorSuccess">Success</InputLabel>
                      <OutlinedInput
                        id="colorSuccess"
                        label="Success"
                        value={formik.values?.config?.colors?.success}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box sx={{
                              width: '25px',
                              height: '25px',
                              borderRadius: '5px',
                              border: '2px white solid',
                              backgroundColor: formik.values?.config?.colors?.success
                            }} />
                          </InputAdornment>
                        }
                        onClick={() => setDisplaySuccessColorPicker(!displaySuccessColorPicker)}
                      />
                    </FormControl>
                    <Box sx={{ position: 'absolute', zIndex: '1000' }} ref={refSuccessColorPicker}>
                      {displaySuccessColorPicker &&
                        <ChromePicker
                          color={formik.values?.config?.colors?.success}
                          onChange={(value) => formik.setFieldValue('config.colors.success', value?.hex)}
                          disableAlpha
                        />
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel htmlFor="colorError">Error</InputLabel>
                      <OutlinedInput
                        id="colorError"
                        label="Error"
                        value={formik.values?.config?.colors?.error}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box sx={{
                              width: '25px',
                              height: '25px',
                              borderRadius: '5px',
                              border: '2px white solid',
                              backgroundColor: formik.values?.config?.colors?.error
                            }} />
                          </InputAdornment>
                        }
                        onClick={() => setDisplayErrorColorPicker(!displayErrorColorPicker)}
                      />
                    </FormControl>
                    <Box sx={{ position: 'absolute', zIndex: '1000' }} ref={refErrorColorPicker}>
                      {displayErrorColorPicker &&
                        <ChromePicker
                          color={formik.values?.config?.colors?.error}
                          onChange={(value) => formik.setFieldValue('config.colors.error', value?.hex)}
                          disableAlpha
                        />
                      }
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={callStatus === 'form'} onChange={handlePanelChange('form')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FeedOutlinedIcon sx={{ mr: 1 }} />
                <Typography>{t('__widget_form')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={formik.values.config.callStatus.form?.show || false}
                          onChange={(e, value) => formik.setFieldValue(`config.callStatus.form.show`, value)}
                        />
                      }
                      label={t("__widget_form_show")}
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_media")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.form.media')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.form?.media) || Boolean(error?.error_details?.config?.callStatus?.form?.media))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.form?.media || error?.error_details?.config?.callStatus?.form?.media)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_title")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.form.title')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.form?.title) || Boolean(error?.error_details?.config?.callStatus?.form?.title))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.form?.title || error?.error_details?.config?.callStatus?.form?.title)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_subtitle")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.form.subtitle')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.form?.subtitle) || Boolean(error?.error_details?.config?.callStatus?.form?.subtitle))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.form?.subtitle || error?.error_details?.config?.callStatus?.form?.subtitle)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_button_send")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.form.button_send')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.form?.button_send) || Boolean(error?.error_details?.config?.callStatus?.form?.button_send))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.form?.button_send || error?.error_details?.config?.callStatus?.form?.button_send)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={['NAME', 'SURNAME', 'EMAIL', 'PHONE']}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={formik.values.config.callStatus.form?.fields || []}
                      onChange={(e, value) => formik.setFieldValue(`config.callStatus.form.fields`, value)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label={t('__widget_form_fields')} placeholder={t('__widget_form_fields')} />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={callStatus === 'standBy'} onChange={handlePanelChange('standBy')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <AddIcCallOutlinedIcon sx={{ mr: 1 }} />
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('__widget_standBy')}</Typography>
                {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_media")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.standBy.media')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.standBy?.media) || Boolean(error?.error_details?.config?.callStatus?.standBy?.media))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.standBy?.media || error?.error_details?.config?.callStatus?.standBy?.media)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_title")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.standBy.title')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.standBy?.title) || Boolean(error?.error_details?.config?.callStatus?.standBy?.title))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.standBy?.title || error?.error_details?.config?.callStatus?.standBy?.title)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_subtitle")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.standBy.subtitle')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.standBy?.subtitle) || Boolean(error?.error_details?.config?.callStatus?.standBy?.subtitle))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.standBy?.subtitle || error?.error_details?.config?.callStatus?.standBy?.subtitle)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_button_request_call")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.standBy.button_request_call')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.standBy?.button_request_call) || Boolean(error?.error_details?.config?.callStatus?.standBy?.button_request_call))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.standBy?.button_request_call || error?.error_details?.config?.callStatus?.standBy?.button_request_call)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={callStatus === 'ringing'} onChange={handlePanelChange('ringing')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <PhoneCallbackOutlinedIcon sx={{ mr: 1 }} />
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('__widget_ringing')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_media")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.ringing.media')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.ringing?.media) || Boolean(error?.error_details?.config?.callStatus?.ringing?.media))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.ringing?.media || error?.error_details?.config?.callStatus?.ringing?.media)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_title")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.ringing.title')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.ringing?.title) || Boolean(error?.error_details?.config?.callStatus?.ringing?.title))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.ringing?.title || error?.error_details?.config?.callStatus?.ringing?.title)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_subtitle")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.ringing.subtitle')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.ringing?.subtitle) || Boolean(error?.error_details?.config?.callStatus?.ringing?.subtitle))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.ringing?.subtitle || error?.error_details?.config?.callStatus?.ringing?.subtitle)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_button_pick_up")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.ringing.button_pick_up')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.ringing?.button_pick_up) || Boolean(error?.error_details?.config?.callStatus?.ringing?.button_pick_up))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.ringing?.button_pick_up || error?.error_details?.config?.callStatus?.ringing?.button_pick_up)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_button_hang_up")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.ringing.button_hang_up')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.ringing?.button_hang_up) || Boolean(error?.error_details?.config?.callStatus?.ringing?.button_hang_up))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.ringing?.button_hang_up || error?.error_details?.config?.callStatus?.ringing?.button_hang_up)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={callStatus === 'inProgress'} onChange={handlePanelChange('inProgress')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <CallOutlinedIcon sx={{ mr: 1 }} />
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('__widget_inProgress')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_media")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.inProgress.media')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.inProgress?.media) || Boolean(error?.error_details?.config?.callStatus?.inProgress?.media))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.inProgress?.media || error?.error_details?.config?.callStatus?.inProgress?.media)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_title")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.inProgress.title')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.inProgress?.title) || Boolean(error?.error_details?.config?.callStatus?.inProgress?.title))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.inProgress?.title || error?.error_details?.config?.callStatus?.inProgress?.title)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_subtitle")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.inProgress.subtitle')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.inProgress?.subtitle) || Boolean(error?.error_details?.config?.callStatus?.inProgress?.subtitle))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.inProgress?.subtitle || error?.error_details?.config?.callStatus?.inProgress?.subtitle)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_button_hang_up")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.inProgress.button_hang_up')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.inProgress?.button_hang_up) || Boolean(error?.error_details?.config?.callStatus?.inProgress?.button_hang_up))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.inProgress?.button_hang_up || error?.error_details?.config?.callStatus?.inProgress?.button_hang_up)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={callStatus === 'completed'} onChange={handlePanelChange('completed')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <StarOutlineOutlinedIcon sx={{ mr: 1 }} />
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{t('__widget_completed')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_media")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.completed.media')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.completed?.media) || Boolean(error?.error_details?.config?.callStatus?.completed?.media))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.completed?.media || error?.error_details?.config?.callStatus?.completed?.media)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_title")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.completed.title')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.completed?.title) || Boolean(error?.error_details?.config?.callStatus?.completed?.title))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.completed?.title || error?.error_details?.config?.callStatus?.completed?.title)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_subtitle")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.completed.subtitle')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.completed?.subtitle) || Boolean(error?.error_details?.config?.callStatus?.completed?.subtitle))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.completed?.subtitle || error?.error_details?.config?.callStatus?.completed?.subtitle)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("__widget_form_button_send")}
                      fullWidth
                      {...formik.getFieldProps('config.callStatus.completed.button_send')}
                      error={Boolean(formik.submitCount) && (Boolean(formik.errors?.config?.callStatus?.completed?.button_send) || Boolean(error?.error_details?.config?.callStatus?.completed?.button_send))}
                      helperText={Boolean(formik.submitCount) && (formik.errors?.config?.callStatus?.completed?.button_send || error?.error_details?.config?.callStatus?.completed?.button_send)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        {/* PREVIEW */}
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <>
            {open &&
              <Card
                sx={{
                  // position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  // bottom: 90,
                  // right: 20,
                  width: 300,
                  maxWidth: 300,
                  height: 390
                }}
                elevation={3}
              >

                {(callStatus === 'form' || (!callStatus && formik.values.config.callStatus.form?.show)) &&
                  <>
                    {cardMedia(formik.values?.config?.callStatus?.form?.media)}
                    <CardContent sx={{ overflow: 'auto', flex: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: '600' }}>{formik.values?.config?.callStatus?.form?.title}</Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>{formik.values?.config?.callStatus?.form?.subtitle}</Typography>
                      <Grid container spacing={2}>
                        {formik.values?.config?.callStatus?.form?.fields?.map(field =>
                          <Grid item xs={12} key={field}>
                            <TextField
                              label={t(field)}
                              size="small"
                              fullWidth
                            />
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </>
                }
                {(callStatus === 'standBy' || (!callStatus && !formik.values.config.callStatus.form?.show)) &&
                  <>
                    {cardMedia(formik.values?.config?.callStatus?.standBy?.media)}
                    <CardContent sx={{ overflow: 'auto', flex: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: '600' }}>{formik.values?.config?.callStatus?.standBy?.title}</Typography>
                      <Typography variant="body2" color="text.secondary">{formik.values?.config?.callStatus?.standBy?.subtitle}</Typography>
                    </CardContent>
                  </>
                }
                {callStatus === 'ringing' &&
                  <>
                    {cardMedia(formik.values?.config?.callStatus?.ringing?.media)}
                    <CardContent sx={{ overflow: 'auto', flex: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: '600' }}>{formik.values?.config?.callStatus?.ringing?.title}</Typography>
                      <Typography variant="body2" color="text.secondary">{formik.values?.config?.callStatus?.ringing?.subtitle}</Typography>
                    </CardContent>
                  </>
                }
                {callStatus === 'inProgress' && (
                  openDialPad ?
                    <CardContent sx={{ overflow: 'auto', flex: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>1</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>2</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>3</Button>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>4</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>5</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>6</Button>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>7</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>8</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>9</Button>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>*</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>0</Button>
                        <Button variant="" sx={{ m: 1, p: 1.5, fontSize: '16px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;' }}>#</Button>
                      </Box>
                    </CardContent>
                    :
                    <>
                      {cardMedia(formik.values?.config?.callStatus?.inProgress?.media)}
                      <CardContent sx={{ overflow: 'auto', flex: 1 }}>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: '600' }}>{formik.values?.config?.callStatus?.inProgress?.title}</Typography>
                        <Typography variant="body2" color="text.secondary">{formik.values?.config?.callStatus?.inProgress?.subtitle}</Typography>
                      </CardContent>
                    </>
                )}
                {callStatus === 'completed' &&
                  <>
                    {cardMedia(formik.values?.config?.callStatus?.completed?.media)}
                    <CardContent sx={{ overflow: 'auto', flex: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: '600' }}>{formik.values?.config?.callStatus?.completed?.title}</Typography>
                      <Typography variant="body2" color="text.secondary">{formik.values?.config?.callStatus?.completed?.subtitle}</Typography>
                      <Rating
                        name="rating"
                        value={rating}
                        sx={{ mt: 2 }}
                        size='large'
                        onChange={(event, newValue) => {
                          setRating(newValue);
                        }}
                      />
                    </CardContent>
                  </>
                }

                <CardActions sx={{ justifyContent: 'center' }}>
                  {(callStatus === 'form' || (!callStatus && formik.values.config.callStatus.form?.show)) &&
                    <Button variant="contained" sx={{ width: '100%', color: common.white, backgroundColor: formik.values?.config?.colors?.main, ":hover": { backgroundColor: darken(formik.values?.config?.colors?.main, 10) } }} onClick={() => setCallStatus('standBy')}>{formik.values?.config?.callStatus?.form?.button_send}</Button>
                  }
                  {(callStatus === 'standBy' || (!callStatus && !formik.values.config.callStatus.form?.show)) &&
                    <Button variant="contained" sx={{ width: '100%', color: common.white, backgroundColor: formik.values?.config?.colors?.main, ":hover": { backgroundColor: darken(formik.values?.config?.colors?.main, 10) } }} onClick={() => setCallStatus('ringing')}>{formik.values?.config?.callStatus?.standBy?.button_request_call}</Button>
                  }
                  {callStatus === 'ringing' &&
                    <>
                      <Button variant="contained" sx={{ width: '100%', color: common.white, backgroundColor: formik.values?.config?.colors?.error, ":hover": { backgroundColor: darken(formik.values?.config?.colors?.error, 10) } }} onClick={() => setCallStatus('standBy')}><CallEndOutlinedIcon sx={{ mr: 1 }} />{formik.values?.config?.callStatus?.ringing?.button_hang_up}</Button>
                      <Button variant="contained" sx={{ width: '100%', color: common.white, backgroundColor: formik.values?.config?.colors?.success, ":hover": { backgroundColor: darken(formik.values?.config?.colors?.success, 10) } }} onClick={() => setCallStatus('inProgress')}><CallOutlinedIcon sx={{ mr: 1 }} />{formik.values?.config?.callStatus?.ringing?.button_pick_up}</Button>
                    </>
                  }
                  {callStatus === 'inProgress' &&
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Button variant="" sx={{ minWidth: 30, padding: '6px 9px' }} onClick={() => { setOpenDialPad(!openDialPad); }}>
                          {openDialPad ? <DialpadOutlinedIcon style={{ color: formik.values?.config?.colors?.main }} /> : <DialpadOutlinedIcon />}
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button variant="" sx={{ minWidth: 30, padding: '6px 9px' }} onClick={() => setMuted(!muted)}>
                          {muted ? <MicOffIcon color='error' /> : <MicIcon />}
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button variant="" sx={{ minWidth: 30, padding: '6px 9px' }} onClick={() => setOnHold(!onHold)}>
                          {onHold ? <PauseIcon color='error' /> : <PauseIcon />}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" sx={{ width: '100%', color: common.white, backgroundColor: formik.values?.config?.colors?.error, ":hover": { backgroundColor: darken(formik.values?.config?.colors?.error, 10) } }} onClick={() => setCallStatus('completed')}><CallEndOutlinedIcon sx={{ mr: 1 }} />{formik.values?.config?.callStatus?.inProgress?.button_hang_up}</Button>
                      </Grid>
                    </Grid>
                  }
                  {callStatus === 'completed' &&
                    <>
                      <Button variant="contained" sx={{ width: '100%', color: common.white, backgroundColor: formik.values?.config?.colors?.main, ":hover": { backgroundColor: darken(formik.values?.config?.colors?.main, 10) } }} onClick={() => setCallStatus('standBy')}>{formik.values?.config?.callStatus?.completed?.button_send}</Button>
                    </>
                  }
                </CardActions>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '10px', textAlign: 'right', pr: 1 }}>Powered by <Link variant="body2" underline="none" color="primary" href="https://walmeric.com" target="_blank" sx={{ fontSize: '10px' }}>® Walmeric</Link></Typography>
              </Card>
            }
            <Fab
              aria-label='phone'
              sx={{
                // position: 'absolute',
                // bottom: 20,
                // right: 20,
                mt: 2,
                backgroundColor: formik.values?.config?.colors?.main,
                ":hover": { backgroundColor: darken(formik.values?.config?.colors?.main, 10) }
              }}
              onClick={() => setOpen(!open)}
            >
              {open ?
                <CloseOutlinedIcon sx={{ color: common.white }} />
                :
                <>
                  <LocalPhoneOutlinedIcon sx={{ color: common.white }} />
                  {/* <img src={w} style={{ position: "absolute" }} alt=""></img> */}
                </>
              }
            </Fab>
          </>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WidgetForm;